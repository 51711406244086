import { FC, memo } from "react";
import Script from "next/script";
import { ErrorBoundary } from "next/dist/client/components/error-boundary";

import {
  StoryBlokAnnouncementBanner,
} from "../components/storyblok/page/StoryBlokAnnouncementBanner";
import Header from "../components/page/Header/Header";
import Main from "../components/layout/Main/Main";
import Footer from "../components/page/Footer/Footer";
import CookieBar from "../components/page/CookieBar/CookieBar";
import SectionNewsletterSignModal from "../components/section/SectionNewsletterSignModal/SectionNewsletterSignModal";
import Error from "../app/error";
import NibblesUpsell from "../components/customer/NibblesUpsell";

import { HEADER_SCRIPTS, ILayout } from "./layouts.map";

const DefaultLayout: FC<ILayout> = ({
  scripts,
  schemaScripts,
  headerLinks,
  hideAnnouncementBanner = false,
  hideCookieBanner = false,
  announcementBanner,
  cookieBanner,
  // head,
  // lastBuilt,
  footer,
  newsletterModal,
  children }) => (
  <div>
    <NibblesUpsell />
    { scripts?.map((script) => (
      <Script key={script} type="text/javascript" src={HEADER_SCRIPTS[script]} async />
    )) }
    { schemaScripts?.map((script) => (
      <Script
        key={script.id}
        id={script.id}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(script.data),
        }}
      />
    )) }

    { !hideAnnouncementBanner && (
      <StoryBlokAnnouncementBanner
        {...announcementBanner}
        domPosition="ABOVE_HEADER"
      />
    ) }
    <Header
      {...headerLinks}
      hasAnnouncementBanner={!hideAnnouncementBanner}
    />
    { !hideAnnouncementBanner && (
      <StoryBlokAnnouncementBanner
        {...announcementBanner}
        domPosition="BELOW_HEADER"
      />
    ) }
    <Main>
      <ErrorBoundary errorComponent={Error}>
        { children }
      </ErrorBoundary>
    </Main>
    { footer && (
      <Footer {...footer}/>
    ) }

    { (!hideCookieBanner && cookieBanner) && (
      <CookieBar {...cookieBanner}/>
    ) }
    { newsletterModal && (
      <SectionNewsletterSignModal {...newsletterModal}/>
    ) }
  </div>
);
export default memo(DefaultLayout);
